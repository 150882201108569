#search_block_top {
  display: block;
  float: right;
  padding-top: 10px; }

#searchbox {
  position: relative;
  z-index: 1; }
  #searchbox .button-search {
    display: block;
    position: absolute;
    z-index: 10;
    width: 41px;
    height: 39px;
    top: 1px;
    right: 1px;
    padding: 10px;
    border: none; }
    #searchbox .button-search svg {
      transition: all 0.2s; }
    #searchbox .button-search:hover {
      background: #E72886; }
      #searchbox .button-search:hover svg {
        fill: #fff;
        transition: all 0.2s; }

#search_query_top {
  box-shadow: none;
  background: #fff;
  padding: 10px 50px 10px 15px;
  height: auto; }
